<template>
  <div class="chroma" :style="{background: options.background.color}"> 
    <div class="leftRight"></div>
    <div class="topBottom"></div>
    <transition-group appear @leave="moderatedLeave" class="texts">
      <div v-for="data in texts" :key="data.time" class="text-wrapper" :style="{borderColor: `#${data.player.color}`}">
        <player-avatar :player="data.player" :scale="3" :showUsername="false" :horizontal="true" />
        <div>
          <div class="username">{{ data.player.username }}</div>
          <div>Envió una pregunta al orador</div>
          <div v-if="data.category" class="categorie">{{ data.category }}</div>
        </div>
      </div>
    </transition-group>

    <transition appear @before-enter="moderatedBeforeEnter" @enter="moderatedEnter" @leave="moderatedLeave">
      <div v-if="moderatedReaction" class="moderationText">
        <player-avatar :player="moderatedReaction.player" :scale="5" :showUsername="true" />
        <div class="text">{{ moderatedReaction.text }}</div>
      </div>
    </transition>
  </div>
</template>
  
<script>
import gsap from "gsap";
import PlayerAvatar from "../PlayerAvatar.vue";

export default {
  components: {
    PlayerAvatar
  },
  data() {
    return {
      texts: [],
      moderatedReaction: undefined,
      duracionPlayerEnvioPregunta: 3, // en segundos
    };
  },
  props: {
      options: {
      type: Object,
      required: true,
      },
  },
  computed: {
    players() {
      return this.$store.state.space.players.filter(x => !x.queue)
    },
  },
  methods: {
    moderatedBeforeEnter(el) {
      el.style.transform = "translateY(0) translateX(-50%)";
    },
    moderatedEnter(el) {
      gsap.to(el, {
        y: '-50%',
        duration: .5,
        ease: "power1.out"
      })
    },
    moderatedLeave () {},
    LimpiarListaEnvioPregunta(){
      const borrar = this.texts.filter(x=> x.time - Date.now() < (this.duracionPlayerEnvioPregunta * -1000) )
      borrar.forEach((item) => {
        this.texts.splice(this.texts.indexOf(item), 1)
      })
      setTimeout(()=>{
        this.LimpiarListaEnvioPregunta()
      }, 300)
    }
  },
  mounted() {
    this.LimpiarListaEnvioPregunta()

  },
  beforeDestroy() {
  },
  sockets: {
    QuitarPreguntaActual(){
      this.moderatedReaction = undefined
    },
    ModeratedReaction(data) {
      console.log("Moderated reaction sent", data)

      if(this.moderatedReaction == undefined){
        // No habia pregunta. La mando directo
        this.moderatedReaction = {
          text: data.reaction,
          player: data.user
        }
      }else{
        // Hay una pregunta. Primero la borramos
        this.moderatedReaction = undefined
        // Y en unos segundos mandamos la nueva pregunta
        setTimeout(() => {
          this.moderatedReaction = {
            text: data.reaction,
            player: data.user
          }
        }, 1500);
      }

    },
    SendReaction(data) {
      if (data.type == 'text') {
        var player = this.players.filter(x => x.dbid == data.dbid)[0]
        if (player) this.texts.push({
          player,
          category: data.category,
          time: Date.now()
        })
      }
    },
    FakeReaction(data){
      console.log('fake',data)
      this.texts.push({
        player: data.player,
        category: data.category,
        time: Date.now()
      })
    }
    
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.chroma {
  height: 100vh;

  $border: solid 1px rgba(0, 128, 0, 0.4);

  .leftRight {
      display: block;
      width: 700px;
      height: 100vh;
      position: absolute;
      right: 0;
      top: 0;
      border-left: $border;
  }

  .topBottom{
    display: block;
    width: 1220px;
    height: 50vh;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: $border;
  }

  .texts {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 9;
    display: flex;
    height: 28rem;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
    overflow: hidden;

    .text-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 0.5em;
      padding: .9em 1.8em .8em .7em;
      border-radius: calc($general-border-radius * 2);
      width: 30rem;
      background: $white;
      font-size: 1.5rem;
      border-style: solid;
      border-width: 4px;
      transition: all .5s;
      z-index: 0;

      .username {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 21px;
        line-height: 1;
        text-transform: uppercase;
      }

      .categorie {
        width: 22rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        font-size: 1.25rem !important;
        color: $grey;
      }
    }
  }

  .moderationText {
    position: absolute;
    left: 600px;
    top: 280px;
    width: 600px;
    background: $white;
    padding: 2em 2em 1em 2em;
    gap: 1em;
    transform: translate(-50%, -50%);
    border-radius: calc($general-border-radius * 2);
    font-size: 1.5rem;
    transition: all .5s;

    .playerAvatarContainer {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
    }

    .text {
      padding-top: 1em;
      font-size: 31px;
      line-height: 37px;
      text-align: center;
      font-weight: 500;
    }
  }
}
</style>
<style lang="scss">
.chroma {
  .moderationText {
    .username {
      width: auto !important;
      font-family: 'Poppins', sans-serif;
      font-size: 21px;
      font-weight: 700;
    }
  }
}
</style>